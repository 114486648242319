import { FC, useState } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { Box, Container, makeStyles, Theme } from '@material-ui/core';

import { useMobile } from 'hooks';
import Carousel from './Carousel';
import CarouselItem from './CarouselItem';
import CarouselSteps from './CarouselSteps';
import { GridCenter } from '../../../../shared';
import BlueSkyBackground from './BlueSkyBackground';

interface UseStylesProps {
  isMobile: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>((theme: Theme) => ({
  container: {
    height: 'calc(100vh - 64px)',
    maxHeight: 'calc(100vh - 64px)',
    overflowY: 'auto',
  },
  containerChild: {
    display: 'table',
    minHeight: 'calc(100vh - 64px)',
  },
  wrapperChild: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  carouselContainer: {
    width: ({ isMobile }) => (isMobile ? '100%' : '50%'),
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 0,
    backgroundColor: theme.palette.background.default,
  },
  hide: {
    display: 'none',
  },
}));

interface CarouselLayoutProps {
  isMobileCarouselEnabled?: boolean;
}

const CarouselLayout: FC<CarouselLayoutProps> = function CarouselLayout({
  children,
  isMobileCarouselEnabled = false,
}) {
  const isMobile = useMobile();
  const classes = useStyles({ isMobile });
  const [showChild, setShowChild] = useState(false);

  const isMobileCarouselHidden = showChild || !isMobileCarouselEnabled;
  const classNameCarousel = isMobileCarouselHidden ? classes.hide : '';
  const classNameChildren = isMobileCarouselHidden ? '' : classes.hide;
  return (
    <Box position="relative">
      <BlueSkyBackground
        showChild={showChild}
        isMobileCarouselEnabled={isMobileCarouselEnabled}
      />
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            className={isMobile ? classNameCarousel : ''}
          >
            <Box zIndex={1} position="relative">
              <GridCenter className={classes.container}>
                <Carousel
                  steps={CarouselSteps.length}
                  onFinish={() => setShowChild(true)}
                  renderActiveStep={(index: number) => (
                    <CarouselItem step={CarouselSteps[index]} />
                  )}
                />
              </GridCenter>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={clsx(
              classes.containerChild,
              isMobile ? classNameChildren : ''
            )}
          >
            <Box alignItems="center" className={classes.wrapperChild}>
              <Box display="flex" justifyContent="center">
                {children}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CarouselLayout;
