import { makeStyles } from '@material-ui/core';

export const usePageStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
    placeItems: 'center center',
    maxWidth: '420px',
    margin: '0 auto',
  },
  maxWidthPage: {
    maxWidth: '420px',
  },
  title: {
    marginBottom: '20px',
  },
  subtitle: {
    marginBottom: '10px',
  },
  form: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  mt0: { marginTop: '0px' },
  mb2: {
    marginBottom: theme.spacing(2),
  },
}));
