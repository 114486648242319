import { useRouter } from 'next/router';
import {
  Avatar,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useNameComponent } from 'hooks';
import {
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../../../src/helpers/constants';

export const useStyles = makeStyles({
  countryFlagMenuItem: {
    justifyContent: 'center',
  },
  countryFlagAvatar: {
    width: 24,
    height: 24,
  },
});
interface ICountryFlagsProps {
  dataCy?: string;
}
export function CountryFlagSelector({ dataCy }: ICountryFlagsProps) {
  const classes = useStyles();
  const router = useRouter();

  const countryId = process.env.NEXT_PUBLIC_COUNTRY_ID;

  const handleCountryChange = () => {
    router.push(`${process.env.NEXT_PUBLIC_OTHER_CLIENT}/${router.pathname}`);
  };
  return (
    <FormControl variant="outlined" size="small" margin="dense">
      <Select
        aria-label="Selector de países"
        value={countryId}
        onChange={handleCountryChange}
        inputProps={{
          'data-cy': useNameComponent(dataCy),
        }}
      >
        <MenuItem
          value={COUNTRY_CODE_CL}
          className={classes.countryFlagMenuItem}
        >
          <Avatar
            className={classes.countryFlagAvatar}
            src={`/assets/locales/${COUNTRY_CODE_CL}/flag.svg`}
            alt={`Bandera de ${COUNTRY_CODE_CL}`}
          />
        </MenuItem>
        <MenuItem
          value={COUNTRY_CODE_MX}
          className={classes.countryFlagMenuItem}
        >
          <Avatar
            className={classes.countryFlagAvatar}
            src={`/assets/locales/${COUNTRY_CODE_MX}/flag.svg`}
            alt={`Bandera de ${COUNTRY_CODE_MX}`}
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
}
