/* eslint no-console: ["error", { allow: ["warn", "log"] }] */
import tracker from '../../helpers/tracker';
import EventType from './types';
import ExperimentsInterface from './experimentTypes';

export interface EventsTrackerRepository {
  sendEvent: (type: EventType, payload?: any) => void;
  setExperiments: (experiments: ExperimentsInterface) => void;
}

export const useEventsTracker = (): EventsTrackerRepository => {
  const sendEvent = (type: EventType, payload: any = {}) => {
    try {
      tracker.event(type, payload);
    } catch (e) {
      console.log('Tracker', e.message);
    }
  };

  const setExperiments = (experiments: ExperimentsInterface = {}) => {
    try {
      tracker.event('USER_SPECIFIC_EXPERIMENT', experiments);
    } catch (e) {
      console.log('Tracker', e.message);
    }
  };

  return {
    sendEvent,
    setExperiments,
  };
};
