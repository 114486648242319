import { useTranslation } from '../../../../features/i18n/infra/useTranslation';

export const usePasswordRules = () => {
  const { t } = useTranslation();
  return {
    required: t('REQUIRED_PASSWORD'),
    minLength: {
      value: 6,
      message: t('ERROR_MIN_LENGTH_PASSWORD'),
    },
  };
};
